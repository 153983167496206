import Home from './components/Home';
import NotFound from './components/NotFound';

import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

const App = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);

export default App;
