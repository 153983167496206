import React, {useEffect} from 'react';
import {gsap} from "gsap";

const Footer = ({screens}) => {
    const {isLgScreen} = screens;

    useEffect(() => {
        let heroSection = document.querySelector(".hero-section");
        let listenerSection = document.querySelector(".listener-section");
        let aboutSection = document.querySelector(".about-section");
        let contactSection = document.querySelector(".contact-section");

        if (!isLgScreen) {
            gsap.fromTo(
                document.querySelector("footer ul"),
                {y: 0, opacity: 0},
                {
                    y: 0, opacity: 1,
                    ease: 'linear',
                    scrollTrigger: {trigger: heroSection, start: "top top", end: "top+=1", scrub: 2}
                }
            );

            gsap.fromTo(
                document.querySelector("footer ul"),
                {y: 0},
                {
                    y: -30,
                    ease: 'linear',
                    scrollTrigger: {trigger: listenerSection, start: "top-=150 top", end: "top-=149", scrub: 2}
                }
            );

            gsap.fromTo(
                document.querySelector("footer ul"),
                {y: -30},
                {
                    y: -60,
                    ease: 'linear',
                    scrollTrigger: {trigger: aboutSection, start: "top-=150 top", end: "top-=149", scrub: 2}
                }
            );

            gsap.fromTo(
                document.querySelector("footer ul"),
                {y: -60},
                {
                    y: -90,
                    ease: 'linear',
                    scrollTrigger: {trigger: contactSection, start: "top-=150 top", end: "top-=149", scrub: 2}
                }
            );
        }
    }, [isLgScreen]);

    return (
        <footer>
            <div className="list-wrap">
                <ul>
                    <li>
                        <span className="num active">01</span>
                        <span className="between">/</span>
                        <span className="num">03</span>
                        <span className="name">LISTENER PLAYLISTS</span>
                    </li>
                    <li>
                        <span className="num active">02</span>
                        <span className="between">/</span>
                        <span className="num">03</span>
                        <span className="name">WE’RE NOT FOR EVERYONE</span>
                    </li>
                    <li>
                        <span className="num active">03</span>
                        <span className="between">/</span>
                        <span className="num">03</span>
                        <span className="name">GET IN TOUCH</span>
                    </li>
                </ul>
            </div>
        </footer>
    )
};

export default Footer;
