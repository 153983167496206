import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => (
    <main>
        <section className="notfound-section">
            <h2>404</h2>
            <p>Похоже, такой страницы не существует :(</p>
            <Link to="/">
                Вернуться на главную страницу
            </Link>
        </section>
    </main>
)

export default NotFound;
