import React, {useState} from 'react';
import emailjs from 'emailjs-com';

const Form = ({handleShowForm, isFormHidden}) => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [isError, setIsError] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsEmailSending(true);
        emailjs.sendForm(
            'service_fmp0vel',
            'template_yvw2l6q',
            e.target,
            'kuMIhDMFjUYMJY9g1'
        ).then(res => {
            setIsEmailSending(false);
            setIsEmailSent(true);
        }).catch(err => {
            setIsError(true);
        });
    }

    return (
        <section className={`form-section${isFormHidden ? ' hidden' : ''}`} id="form-section">
            <div onClick={handleShowForm} className="close-icon-wrap">
                <span className="icon"></span>
                <span className="icon"></span>
            </div>
            <form onSubmit={sendEmail}>
                <div className="fields-wrap">
                    <div className="form-group">
                        <label className="form-label" htmlFor="formFirstnameInput">First Name*</label>
                        <input type="text" name="firstname" className="form-control" id="formFirstnameInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formLastnameInput">Last Name*</label>
                        <input type="text" name="lastname" className="form-control" id="formLastnameInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formPhoneInput">Phone Number*</label>
                        <input type="tel" name="phone" className="form-control" id="formPhoneInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formEmailInput">E-Mail*</label>
                        <input type="email" name="email" className="form-control" id="formEmailInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formRecordInput">Record Label*</label>
                        <input type="text" name="record" className="form-control" id="formRecordInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formUrlInput">Song URL*</label>
                        <input type="text" name="url" className="form-control" id="formUrlInput" required/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="formCommentsInput">Message*</label>
                        <textarea name="comments" className="form-control" id="formCommentsInput" required/>
                    </div>
                    <button
                        type="submit"
                        className={`btn-form ${(isEmailSent || isError) && 'disabled'}`}
                        disabled={isEmailSent || isError}
                        aria-disabled={isEmailSent || isError}
                    >
                        {isEmailSending
                            ? 'Sending...'
                            : isEmailSent
                                ? 'Your request was sent'
                                : isError
                                    ? 'Please try again'
                                    : 'Send'
                        }
                    </button>
                </div>
            </form>
            <div onClick={handleShowForm} className="overlay"></div>
        </section>
    );
}

export default Form;
