import {useEffect, useState} from "react";

const ScrollToTopOnMount = ({screens = {}, setIsPageLoaded}) => {
    const {isMdScreen} = screens;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isMdScreen) {
            window.scrollTo(0, 0);
        }
    }, []);

    useEffect(() => {
        const body = document.body;

        let loadingCounter = document.querySelector(".loading-counter");
        let counting = false;

        const start = (count) => {
            if (!counting) {
                counting = true;
                let timer = isLoading && setInterval(() => {
                    if (count < 100) {
                        loadingCounter.innerHTML = count;
                        count++;
                        body.classList.add('hidden');
                    } else {
                        loadingCounter.innerHTML = '100';
                        clearInterval(timer);
                        counting = false;
                        setIsLoading(false);
                        setIsPageLoaded(true);
                    }
                }, 20);
            }

            body.classList.remove('hidden');
        }

        start(0);
    }, [isLoading]);

    return (
        <section className={`loading-section${!isLoading ? ' hidden' : ''}`}>
            <div className="loading-counter">0</div>
        </section>
    )
}

export default ScrollToTopOnMount;
