import React, {useEffect, useState, useCallback} from 'react';
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from "../ScrollToTopOnMount";
import Header from "../Header";
// import PageScroll from "../PageScroll";
import Hero from "../Hero";
// import Listener from "../Listener";
// import About from "../About";
// import Contact from "../Contact";
import Form from "../Form";
import Footer from "../Footer";

const Home = () => {
    gsap.registerPlugin(ScrollSmoother, ScrollTrigger, DrawSVGPlugin);
    const [isFormHidden, setIsFormHidden] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {isXsScreen, isSmScreen, isMdScreen, isLgScreen};

    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    appHeightHandle();

    useEffect(() => {
        if (!isLgScreen) {
            window.addEventListener('resize', () => {
                appHeightHandle();
            });
        }
    }, [isLgScreen]);

    useEffect(() => {
        if (isLgScreen) {
            let smoother;
            const smootherSetup = () => {
                smoother = ScrollSmoother.create({
                    smooth: 2,
                    smoothTouch: true,
                    normalizeScroll: true
                });
            }

            smootherSetup();

            if (isFormHidden) {
                smootherSetup();
            } else {
                smoother.kill();
            }

            // ScrollTrigger.normalizeScroll(true);
            // if (isFormHidden) {
            //     ScrollTrigger.normalizeScroll(true);
            //     console.log('ON');
            // } else {
            //     ScrollTrigger.normalizeScroll(false);
            //     console.log('OFF');
            // }
        } else {
            const smoother = ScrollSmoother.create({
                wrapper: "#smoother-wrapper",
                content: "#smooth-content",
                smooth: 3,
                effects: true,
                normalizeScroll: false,
                ease: 'power4.out',
            });

            let nativeScrollVelocity = smoother.scrollTrigger.getVelocity();
        }
    }, [isLgScreen, isFormHidden, ScrollSmoother, ScrollTrigger]);

    const handleShowForm = useCallback(() => {
        const body = document.body;

        if (isFormHidden) {
            body.classList.add('hidden');
            setIsFormHidden(false);
        } else {
            body.classList.remove('hidden');
            setIsFormHidden(true);
        }
    }, [isFormHidden, setIsFormHidden])

    return (
        <>
            <ScrollToTopOnMount screens={screens} setIsPageLoaded={setIsPageLoaded}/>
            <Header handleShowForm={handleShowForm} screens={screens} className={!isPageLoaded ? 'invisible' : ''}/>
            <main className={!isPageLoaded ? 'invisible' : ''}>
                <Form handleShowForm={handleShowForm} isFormHidden={isFormHidden} screens={screens}/>
                <div id="smooth-wrapper">
                    <div id="smooth-content">
                        <Hero screens={screens}/>
                        {/*<Listener screens={screens}/>*/}
                        {/*<About screens={screens}/>*/}
                        {/*<Contact handleShowForm={handleShowForm} screens={screens}/>*/}
                    </div>
                </div>
            </main>
            <Footer screens={screens} className={!isPageLoaded && 'invisible'}/>
        </>
    )
}

export default Home;
