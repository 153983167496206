import React from 'react';
// import {gsap} from "gsap";
import {Container, Row, Col} from "react-bootstrap";

import heroBg from "assets/img/hero-bg.png";
import heroText from "assets/img/hero-text.svg";
import circleIcon from "assets/img/its-no-request-icon.svg";
// import scrollIcon from "assets/img/scroll-arrow-icon.svg";

const Hero = ({screens = {}}) => {
    // const {isLgScreen} = screens;

    // useEffect(() => {
    //     let heroSection = document.querySelector(".hero-section");
    //     let heroAnimationSettings = {
    //         trigger: heroSection,
    //         start: isLgScreen ? "top+=150 top" : "top+=50 top",
    //         end: isLgScreen ? "top+=151 top" : "top+=51",
    //         scrub: 2,
    //         ease: "power4.inOut"
    //     };
    //
    //     gsap.fromTo(
    //         document.querySelector(".hero-section-bg"),
    //         {y: 0},
    //         {
    //             y: -100, ease: 'linear', scrollTrigger: {
    //                 trigger: heroSection,
    //                 start: "bottom bottom",
    //                 end: "bottom",
    //                 scrub: true,
    //             }
    //         }
    //     );
    //
    //     gsap.fromTo(
    //         document.querySelector(".hero-section .circle-wrap img"),
    //         {opacity: 1, y: 0},
    //         {opacity: 0, y: isLgScreen ? -150 : '-10vw', ease: 'linear', scrollTrigger: heroAnimationSettings}
    //     );
    //
    //     gsap.fromTo(
    //         document.querySelector(".hero-section .img-wrap img"),
    //         {y: 0, opacity: 1},
    //         {y: isLgScreen ? -40 : '-12vw', opacity: isLgScreen ? 0 : 1, scrollTrigger: heroAnimationSettings}
    //     );
    //
    //     gsap.fromTo(
    //         document.querySelector(".hero-section .scroll-wrap p"),
    //         {y: 0},
    //         {y: isLgScreen ? 30 : '1.15vw', scrollTrigger: heroAnimationSettings}
    //     );
    //
    //     gsap.fromTo(
    //         document.querySelector(".hero-section .scroll-wrap-img"),
    //         {y: 0},
    //         {y: isLgScreen ? 30 : '1.15vw', scrollTrigger: heroAnimationSettings}
    //     );
    // }, [isLgScreen]);

    return (
        <section className="hero-section" id="hero-section">
            <div className="hero-section-bg">
                <img src={heroBg} alt="Hero Bg"/>
            </div>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="circle-wrap">
                            <img src={circleIcon} alt="Circle Icon"/>
                        </div>
                        <div className="img-wrap">
                            <img src={heroText} alt="Hero Text Img"/>
                        </div>
                        {/*<div className="scroll-wrap">*/}
                        {/*    <div className="scroll-wrap-text">*/}
                        {/*        <p>SCROLL TO DISCOVER</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="scroll-wrap-img">*/}
                        {/*        <img src={scrollIcon} alt="Scroll Icon"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Hero;
