import React, {useState, useEffect} from 'react';
import {Navbar} from "react-bootstrap";

// import logo from "assets/img/logo.svg";

const Header = ({screens, handleShowForm}) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const {isMdScreen} = screens;
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const header = document.querySelector('header');
        if (isMdScreen && scrollPosition > 100) {
            header.classList.add('bg');
        } else {
            header.classList.remove('bg');
        }
    }, [isMdScreen, scrollPosition]);

    return (
        <header>
            <Navbar collapseOnSelect expand="all">
                <div className="navbar-medium-wrap">
                    <a href="https://medium.com/@itsnorequests" target="_blank" className="btn-main">
                        <div className="icons-wrap">
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </div>
                        <p>MEDIUM</p>
                    </a>
                </div>
                <div className="navbar-contact-wrap">
                    <a onClick={handleShowForm} className="btn-main">CONTACT US</a>
                </div>
            </Navbar>
        </header>
    )
};

export default Header;
